@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');

body, h1, h3, p, input, label, button {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

body {
    background: radial-gradient(circle, #1A1E2B, #212C3C, #1B1E2C);
    color: white;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 1.5px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

h1,
h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 80px;
    letter-spacing: 1.5px;
}

h1{
  font-size: 64px;
}

h2 {
  font-size: 28px;
  text-transform: uppercase;
}

p {
  font-family: 'Montserrat', sans-serif;
}

.orange {
    color: #FA8714;  
}


.app-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.app-card {
  flex: 1 1 300px; 
  max-width: 400px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: rgba(26, 30, 43, 0.8);
  border-radius: 25px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7px);
}

.app-card h2 {
  margin-bottom: 10px;
}

.app-card p {
  font-size: 18px;
  margin-bottom: 15px;
}

.app-card .category {
  font-size: 16px;
  color: #FA8714;
  margin-bottom: 20px;
}

.app-button {
  background-color: #FA8714;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: auto;
  transition: background-color 0.3s ease;
}

.app-button:hover {
  background-color: white;
  color: #E67300;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .app-grid {
      justify-content: flex-start;
  }
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.input-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.input-group {
    background-color: rgba(26, 30, 43, 0.8);
    border-radius: 25px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(7px);
    width: 100%;
    max-width: 600px;
}

.input-group p {
    margin: 15px 10px;
}

label {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
    padding: 20px;
    text-align: left;
}

input[type="text"], input[type="file"], textarea {
    width: calc(100% - 20px);
    padding: 10px;
    font-size: 16px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    letter-spacing: 1px;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
}

.info-icon {
    font-size: 18px;
    margin-left: 5px;
    color: #FA8714;
}

.tooltip-text {
    visibility: hidden;
    width: 250px;
    background-color: #555;
    font-style: italic;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position tooltip above the icon */
    left: 50%;
    margin-left: -125px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

textarea {
    min-height: 100px;
    resize: vertical;
}

/* Button Styles */
.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

button {
    background-color: #FA8714;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    border-radius: 15px;
    padding: 15px 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    margin-bottom: 80px;
}

button:hover {
    background-color: white;
    color: #E67300;
}

.error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ef9a9a;
}

/* Modal styles */
.modal {
    position: fixed;
    color: black;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    border-radius: 25px;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    max-height: 330px;
}

.questions {
  background-color: rgba(26, 30, 43, 0.8);
  border-radius: 25px;
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7px);
  width: 100%;
  max-width: 800px;
  text-align: left; 
}

.questions h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 15px;
}

.questions ul {
    list-style-type: none;
    padding: 0;
}

.questions li {
    list-style-type: none;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 300;
}

.summary {
  background-color: rgba(26, 30, 43, 0.8);
  border-radius: 25px;
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7px);
  width: 85%; 
  max-width: 800px;
  text-align: left;
}

.summary h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 15px;
}

.summary p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
}

/* Footer.css */
.footer {
    margin-top: auto; /* Pushes the footer to the bottom of the page */
    text-align: center;
    padding: 10px;
    font-size: 14px;
}

.footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between text and logo */
}

.footer-text {
    margin: 5px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.2em;
}

.footer-link {
    color: #FA8714; /* Match the button color */
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-logo {
    height: 20px; /* Adjust the size of the logo as needed */
    vertical-align: middle;
}

/* Decorative line above social icons */
.decorative-line {
    width: 100%;
    max-width: 600px;
    height: 1px;
    margin: 30px auto;
    background: linear-gradient(
      to right,
      white,
      white 20%,
      transparent 50%,
      white 80%,
      white 100%
    );
  }

/* Social Media Icons */
.social-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }
  
  .social-icon {
    color: white;
    font-size: 24px;
    padding: 10px;
    background-color: transparent;
    transition: all 0.3s ease;
  }
  
  .social-icon:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
  

  /* Responsive design for smaller screens */
@media (max-width: 768px) {
    .decorative-line {
        max-width: 90%;
      }

    .social-icons {
      gap: 15px;
    }
  
    .social-icon {
      font-size: 20px;
      padding: 8px;
    }
  }
